<template>
    <page title="孩子详情" class="add-student-page" :hasBack="true" textAlign="left">
        <div class="upload-wrapper">
            <div class="upload-content">
                <div class="upload"  @click="clickUpload">
                    <img
                        class="default"
                        v-if="hasLoad && !formValues.photo"
                        src="@/assets/images/user/default_avatar.png"
                        alt=""
                    />
                    <van-image v-else class="avatar" fit="cover" :src="formValues.photo" />
                </div>
                <van-uploader ref="uploadRef" class="hide" :before-read="beforeRead">

                </van-uploader>
                <div class="tips">请点击上传</div>
            </div>
            <div class="red-tips">本照片用于学校进出校门的人脸识别，请上传学生本人 正面半身照。</div>
        </div>
        <common-block class="name-wrapper" title="填写信息">
            <text-wrapper label="孩子姓名">
                <van-field v-model="formValues.studentName" :disabled="true" placeholder="请点击输入" />
            </text-wrapper>
        </common-block>
        <text-wrapper label="身份证号">
            <van-field v-model="formValues.idcard" placeholder="请点击输入" />
        </text-wrapper>
        <text-wrapper label="联系电话">
            <van-field v-model="formValues.perphone" placeholder="请点击输入" />
        </text-wrapper>
        <text-wrapper label="所属学校">
            <select-picker title="所属学校" :defaultValue="formValues.school"  type="school" @selectConfirm="selectConfirm" />
        </text-wrapper>
        <text-wrapper label="所属班级">
            <select-picker
                title="所属班级"
                type="className"
                :defaultValue="formValues.className"
            />
        </text-wrapper>
        <text-wrapper label="所属关系">
            <select-picker
                title="所属关系"
                type="relation"
                :defaultValue="formValues.relation"
            />
        </text-wrapper>
        <text-wrapper label="走、住读">
            <select-picker
                title="走、住读"
                :columns="readTypeListColumns"
                type="isoncampus"
                @selectConfirm="selectConfirm"
                :defaultValue="formValues.readType"
            />
        </text-wrapper>
        <div class="btn-wrapper">
            <van-button class="confirm-btn" round type="primary" @click="confirmSubmit">确认保存</van-button>
        </div>
        <van-popup class="upload-tips" v-model:show="uploadTipsVisible" round position="bottom">
            <div class="upload-title">
                照片示例
            </div>
            <img
                class="default"
                src="@/assets/images/addStudent/upload.png"
                alt=""
            />
            <div class="btn-wrapper">
                <van-button class="confirm-btn" round type="primary" @click="confirmUpload">确认上传</van-button>
            </div>
        </van-popup>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
import SelectPicker from '@/components/selectPicker/SelectPicker.vue';
import TextWrapper from '@/components/text/text';
import { reactive, ref } from 'vue';
import TimePicker from '@/components/timePicker/TimePicker';
import {Field, Button, Uploader, Image as VanImage, Popup} from 'vant';
import commonRequest from '@/services';
import { getStorage } from '@/utils/storage';
import paramsParse from '@/utils/paramsParse';
import Compressor from 'compressorjs';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

export default {
    name: 'AddStudent',
    components: {
        [Page.name]: Page,
        [CommonBlock.name]: CommonBlock,
        [SelectPicker.name]: SelectPicker,
        [TextWrapper.name]: TextWrapper,
        [TimePicker.name]: TimePicker,
        [Field.name]: Field,
        [Button.name]: Button,
        [Uploader.name]: Uploader,
        [VanImage.name]: VanImage,
        [Popup.name]: Popup,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const uploadRef = ref(null);
        const uploadTipsVisible = ref(false);
        const readTypeListColumns = ref([
            { text: '走读', id: '0' },
            { text: '住读', id: '1' },
        ]);
        const formValues = reactive({

        });
        const relationListColumns = ref([]);
        const hasLoad = ref(false);
        commonRequest({ action: 10002, dCode: 'sys_student_parent_relation' }).then((relationRes) => {
            hasLoad.value = true;
            if (relationRes.data) {
                relationListColumns.value = relationRes.data.map((item) => ({
                    id: item.value,
                    text: item.name,
                }));
                commonRequest({ action: 11012, studentUserid: route.query.id }).then((res) => {
                    if (res.data) {
                        formValues.photo = res.data.photo;
                        formValues.idcard = res.data.idcard;
                        formValues.isoncampus = res.data.isoncampus;
                        formValues.readType = readTypeListColumns.value.find(item => item.id === res.data.isoncampus)?.text;
                        formValues.perphone = res.data.perphone;
                        formValues.relation = res.data.relation;
                        // formValues.relation = relationListColumns.value.find(item => item.id === res.data.relation)?.text ;
                        formValues.studentName = res.data.studentName;
                        formValues.studentUserid = res.data.studentUserid;
                        formValues.school = res.data.studentGradeResponseList?.[0]?.schoolName;
                        formValues.className = res.data.studentGradeResponseList?.[0]?.phaseName +
                            '/' + res.data.studentGradeResponseList?.[0]?.gradeName + '/' + res.data.studentGradeResponseList?.[0]?.className;
                    }
                });
            }
        });
        const selectConfirm = ({ type, value }) => {
            formValues[type] = value;
        };

        const beforeRead = (file) => {
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    // Blob 转换为 File
                    const files = new window.File([result], file.name, {type: file.type});
                    const timestamp = Math.floor(new Date().getTime() / 1000);
                    const signData = {
                        action: 10001,
                        type: 'images',
                        timestamp,
                    };
                    const token = getStorage('token');
                    if (token) {
                        signData.token = token;
                    }
                    const sign = paramsParse(signData);
                    const formData = new FormData();
                    formData.append('action', 10001);
                    formData.append('type', 'images');
                    formData.append('timestamp', timestamp);
                    formData.append('sign', sign);
                    formData.append('token', token);
                    formData.append('files', files);
                    const toast = Toast.loading({
                        message: '上传中...',
                        forbidClick: true,
                        loadingType: 'spinner',
                        duration: 0
                    });
                    commonRequest(formData).then((res) => {
                        toast.clear();
                        if (res.data) {
                            formValues.photo = res.data[0]?.aliyunPath;
                        }
                    });
                }
            })

        };
        const confirmSubmit = () => {
            if (!formValues.idcard) {
                Toast('请输入身份证号');
                return;
            }
            if (!formValues.perphone) {
                Toast('请输入联系电话');
                return;
            }
            if (formValues.isoncampus === '') {
                Toast('请选择走、住读');
                return;
            }
            commonRequest({
                action: 11020,
                studentUserid: formValues.studentUserid,
                photo: formValues.photo,
                idcard: formValues.idcard,
                perphone: formValues.perphone,
                oncampus: formValues.isoncampus,
            }).then(() => {
                Toast.success('修改成功');
                setTimeout(() => {
                    router.back();
                }, 100);
            });
        };
        const clickUpload = () => {
            uploadTipsVisible.value = true;
        };
        const confirmUpload = () => {
            if (uploadRef.value) {
                uploadTipsVisible.value = false;
                setTimeout(() => {
                    uploadRef.value.chooseFile();
                }, 400)
            }
        };
        return {
            formValues,
            beforeRead,
            selectConfirm,
            confirmSubmit,
            readTypeListColumns,
            hasLoad,
            clickUpload,
            uploadRef,
            uploadTipsVisible,
            confirmUpload
        };
    },
};
</script>
<style lang="less" scoped>
.upload-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 15px;
    margin-bottom: 11px;
    .tips {
        font-size: 13px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        margin-top: 5px;
        color: #E64141;
    }
}
.name-wrapper {
    /deep/ .common-content {
        padding-left: 0;
    }
}
.hide {
    display: none;
}
.upload {
    /*width: 80px;*/
    /*height: 110px;*/
    /*background: #e2e2e2;*/
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .default {
        width: 110px;
    }
    .avatar {
        width: 110px;
        height: 135px;
    }
}
.red-tips {
    height: 50px;
    background: #fff1f1;
    border-radius: 5px;
    padding: 7px 23px 0 10px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: PingFang SC, serif;
    line-height: 18px;
    color: #e64141;
}
.btn-wrapper {
    margin-top: 50px;
    text-align: center;
}
/deep/ .van-field {
    padding: 15px 8px 11px;
    .van-field__control {
        line-height: 24px;
    }
}
/deep/ .upload-tips {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-wrapper {
        margin-top: 30px;
    }
    .default {
        height: 115px;
    }
    .upload-title {
        font-size: 18px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        color: #E64141;
        margin-bottom: 30px;
        text-align: center;
    }
}
</style>
